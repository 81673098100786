
import { defineComponent } from "vue";
import SalaryCalculator from "@/components/SalaryCalculator.vue";

export default defineComponent({
  name: "App",
  components: {
    SalaryCalculator,
  },
});
